<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <v-skeleton-loader v-if="overlay" v-bind="attrs" type="article"></v-skeleton-loader>
        <v-card v-else class="card">
            <v-card-title class="justify-center grey white--text">PAY FINE</v-card-title>
            <br />

            <v-row align="center" justify="center">
                <!-- <v-col sm="1"></v-col> -->
                <v-col sm="4">
                    <label style="font-weight: bold; color: blue">Select Member</label><span
                        style="color: red; font-weight: bolder">*</span>
                    <v-combobox class="text" v-model="selecteduser" :items="empmemberlist" item-text="displayname"
                        item-value="username" outlined dense :rules="[rules.required]" clearable required
                        @click="isempselected = false" @change="set1(selecteduser, 'emp')"></v-combobox>
                </v-col>
                <v-col sm="3">
                    <label style="font-weight: bold; color: blue">For Barcode/RFID/QRCode</label><v-text-field outlined
                        v-model="prn" dense class="text" @keypress.enter="findUserInfo(selecteduser)"
                        placeholder="Enter PRN/EMPID"></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">

                <v-btn style="" :disabled="!isempselected" class="primary" @click="findUserInfo(selecteduser)">Fetch
                    Details</v-btn>

            </v-row>
            <br />

            <div v-if="userInfo" class="cus-card">
                <v-row>
                    <v-col cols="12" sm="10" md="10">
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Reg/Emp Code</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span>: {{ empmemberinfo.reg_no }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Name</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span>: {{ empmemberinfo.name }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label style="padding: 0; margin: 0" class="c-label">Contact</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span style="padding: 0; margin: 0">: {{
                                            empmemberinfo.mobile_number }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Email</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span style="padding: 0; margin: 0">: {{
                                            empmemberinfo.email }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">User Type</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span>: {{ empmemberinfo.usertype }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="empmemberinfo.program != ''" cols="12" sm="8" md="8">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Program</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span style="padding: 0; margin: 0">: {{ empmemberinfo.program }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="empmemberinfo.rollno != ''" cols="12" sm="4" md="4">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Roll No</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span style="padding: 0; margin: 0">: {{ empmemberinfo.rollno }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="empmemberinfo.division != ''" cols="12" sm="8" md="8">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Division</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span style="padding: 0; margin: 0">: {{ empmemberinfo.division }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="empmemberinfo.year" cols="12" sm="4" md="4">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <label class="c-label">Year</label>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <span style="padding: 0; margin: 0">: {{ empmemberinfo.year }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>

                        </v-row>
                        <v-row>

                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                        <v-img height="150" width="150" :src="url">
                            <template v-slot:placeholder>
                                <v-icon size="150">mdi mdi-account</v-icon>
                            </template>
                        </v-img>
                    </v-col>
                </v-row>


            </div>

        </v-card>
        <br />

        <div v-if="Array.isArray(fineRecord) && fineRecord.length">
            <v-card>

                <center class="red--text">
                    <h2> Total Fine</h2>
                </center>





                <v-row justify="center" align="center">

                    <v-col cols="12" sm="1" md="1">

                        <v-card class="grey white--text">
                            <center>{{ actual_total_fine }}</center>
                        </v-card>
                    </v-col>
                </v-row>
                <br />


                <v-row justify="center" align="center">
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Enter Total Amount To Be Paid" class="centered-input" @input="finesperator()"
                            v-model="total_amount_paid" type="number">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Receipt No" class="centered-input" @input="check()" v-model="reciept_no">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card>
            <v-card>
                <v-simple-table class="pa-3 ">
                    <thead style="text-align:center;" class="grey white--text">
                        <th style="text-align:center; position: sticky; top: 0;" class="pa-2"> Sr. No </th>
                        <th class="pa-2"> Accession No </th>
                        <th class="pa-2"> Title </th>
                        <th class="pa-2"> Issue Date </th>
                        <th class="pa-2"> Due Date </th>
                        <th class="pa-2"> Return Date</th>
                        <th class="pa-2"> Actual Fine </th>
                        <th class="pa-2"> Pay fine</th>


                    </thead>
                    <tbody style="text-align:center;">
                        <tr v-for="(item, index) in fineRecord" :key="index">
                            <td class="pa-2">{{ index + 1 }}</td>
                            <td class="pa-2">{{ item.acc_no }}</td>
                            <td class="pa-2">{{ item.title }}</td>
                            <td class="pa-2">{{ item.br_date }}</td>
                            <td class="pa-2">{{ item.due_date }}</td>
                            <td class="pa-2">{{ item.return_date }}</td>
                            <td class="pa-2">{{ item.fine }}</td>
                            <td class="pa-2">
                                <v-text-field disabled v-model="item.paid_fine" type="number">

                                </v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
            <br />
            <div v-if="pay_btn_disable" class="red--text">
                <center> Please Enter Total Amount And Reciept No To Enable Pay Button </center>
            </div> <br />
            <v-row align="center" justify="center">

                <v-btn style="" :disabled="pay_btn_disable" class="primary" @click="pay_fine()">
                    PAY</v-btn>

            </v-row>
            <br />
        </div>
        <div v-if="no_fine">
            <v-card class="green white--text">
                <center class="ma-1 pa-1">
                    <h3> No Fine Found.</h3>
                </center>
            </v-card>
        </div>




    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            pay_btn_disable: true,
            actual_total_fine: 0,
            total_amount_paid: 0,
            loading_fine: false,
            overlay: false,
            overlay_fine: false,
            color: "",
            snackbar: false,
            snackbar_msg: "",
            isempselected: true,
            prn: "",
            userInfo: false,
            url: "",
            isphoto: false,
            selecteduser: "",
            empmemberlist: "",
            fineRecord: [],
            reciept_no: "",
            no_fine: false,
            rules: {
                required: (value) => !!value || "Required.",
            },
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'Pay Fine',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
        };
    },
    mounted() {
        // this.getfine("119B1128");
        this.init();
    },
    methods: {
        check() {
            if (this.total_amount_paid == this.actual_total_fine && this.reciept_no)
                this.pay_btn_disable = false;
            else
                this.pay_btn_disable = true;
        },
        pay_fine() {
            if (!this.reciept_no) {
                this.showSnackbar("#b71c1c", "Please Enter Receipt No");
                return
            }

            this.loading_fine = true;
            var params = { prn: this.prn, finedata: this.fineRecord, reciept_no: this.reciept_no };
            axios
                .post("/IssueBook/saveFineNew", params)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.loading_fine = false;
                        this.getfine(this.prn);
                        this.showSnackbar("green", "Fine Paid Successfully.");
                    }

                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                    this.loading_fine = false;
                })
                .finally(() => {
                    this.loading_fine = false;
                });
        },
        finesperator() {
            if (this.total_amount_paid) {
                var sum_fine = this.total_amount_paid
                for (var x in this.fineRecord) {
                    this.fineRecord[x].paid_fine = 0;
                    if (sum_fine >= this.fineRecord[x].fine) {
                        sum_fine = sum_fine - this.fineRecord[x].fine;
                        this.fineRecord[x].paid_fine = this.fineRecord[x].fine;
                    }
                }
            }
            this.check();

        },
        getfine(value) {
            this.fineRecord = [];
            this.no_fine = false;
            this.reciept_no = "";
            this.overlay_fine = true;
            var params = { prn: value };
            axios
                .post("/IssueBook/getFineNew", params)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.fineRecord = res.data.fineRecord;
                        this.actual_total_fine = res.data.total_fine;
                        this.total_amount_paid = res.data.total_fine;
                        this.overlay_fine = false;
                        this.finesperator();
                    }
                    if (res.data.status == "504") {
                        this.no_fine = true;
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                    this.overlay_fine = false;
                })
                .finally(() => {
                    this.overlay_fine = false;
                });
        },
        init() {
            this.overlay = true;
            axios
                .post("/IssueBook/getEmpMemData")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.empmemberlist = res.data.userlist;
                        this.overlay = false;
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                    this.overlay = false;
                })
                .finally(() => {
                    this.overlay = false;
                });
        },

        set1(value, person) {
            if (person == "emp") {
                this.prn = value.displayname.split(":")[0].trim();
                this.isempselected = true;
            } else if (person == "bookbarcode") {
                if (this.accno == "") this.isbookselected = false;
                else this.isbookselected = true;
            }
        },
        findUserInfo(seluser) {
            this.bookLimit = false;
            var params = null;
            // params = { userid: this.selecteduser.username };

            this.overlay1 = true;



            params = { userid: this.prn };
            axios
                .post("/IssueBook/getEmpMemInformationbyprn", params)
                .then((res) => {
                    this.overlay1 = false;
                    if (res.data.msg == "200") {
                        this.userInfo = true;
                        this.empmemberinfo = res.data.memberinfo;

                        this.getimage(this.prn);
                        this.getfine(this.prn);
                    } else {
                        this.showSnackbar("red", res.data.msg);
                        this.userInfo = false;
                    }
                });


        },
        getimage(value) {
            var params = { prn: value };
            axios
                .post("/ImportData/getphotfromerp", params)
                .then((res) => {
                    if (res.data.msg == '200') {
                        this.url = res.data.url;
                        this.isphoto = res.data.isphoto;
                    }


                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style scoped>
.centered-input>>>input {
    text-align: center
}

.add-section {
    display: block;
    margin: 1rem;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}

.cus-card {
    background: #d3d3d33d;
    margin: 1rem;
    padding: 1rem;
    border: 2px solid gray;
}
</style>